import React, { useEffect, Suspense } from "react"
import moment from "moment-timezone"
import Hotjar from "@hotjar/browser"
import { ToastContainer } from "react-toastify"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

import TestTag from "./components/TestTag"
import PublicRoute from "./components/router/PublicRoute"
import ScrollToTop from "./components/custom/ScrollToTop"

const Home = React.lazy(() => import("./screens/Home"))
const BookingInfo = React.lazy(() => import("./screens/bookingInfo"))
const CalenderInfo = React.lazy(
  () => import("./screens/bookingInfo/CalenderInfo"),
)
const AvailabilityInfo = React.lazy(
  () => import("./screens/bookingInfo/AvailabilityInfo"),
)
const InformationInfo = React.lazy(
  () => import("./screens/bookingInfo/InformationInfo"),
)
const ConfirmInfo = React.lazy(
  () => import("./screens/bookingInfo/ConfirmInfo"),
)

const Reservations = React.lazy(() => import("./screens/reservations"))
const GuestInformation = React.lazy(() => import("./screens/reservations"))
const GuestConfirm = React.lazy(() => import("./screens/reservations"))

const BillsInfo = React.lazy(() => import("./screens/payingBillInfo"))
const PayingBillInfo = React.lazy(
  () => import("./screens/payingBillInfo/PayingBillInfo"),
)
const SplitBillInfo = React.lazy(
  () => import("./screens/payingBillInfo/SplitBillInfo"),
)
const PaymentFormInfo = React.lazy(
  () => import("./screens/payingBillInfo/PaymentFormInfo"),
)
const SucceededPaymentInfo = React.lazy(
  () => import("./screens/payingBillInfo/SucceededPaymentInfo"),
)

const ModifyReservation = React.lazy(
  () => import("./screens/modifyReservation"),
)
const FrequentlyAskedQuestions = React.lazy(
  () => import("./components/TermsAndConditions/FrequentlyAskedQuestions"),
)
const TermsAndConditions = React.lazy(
  () => import("./components/TermsAndConditions/TermsAndConditions"),
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 30000,
      cacheTime: 3600000,
      refetchOnWindowFocus: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
})

moment.tz.setDefault("Europe/London")

if (process.env.REACT_APP_HOTJAR_ENABLED) {
  Hotjar.init(
    process.env.REACT_APP_HOTJAR_SITE_ID,
    process.env.REACT_APP_HOTJAR_VERSION,
  )
}

function App() {
  const ENV = process.env.REACT_APP_ENVIRONMENT

  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]')
    if (metaThemeColor) {
      metaThemeColor.setAttribute(
        "content",
        ENV === "production" ? "#18243a" : "#EE4B2B",
      )
    }
  }, [ENV])

  return (
    <Router>
      <div className="text-white min-h-screen bg-color-customblue app">
        <ScrollToTop />
        <TestTag />
        <QueryClientProvider client={queryClient}>
          <Suspense>
            <Routes>
              {/* Home route */}
              <Route path="/" element={<PublicRoute />}>
                <Route index element={<Home />} />
                <Route path="*" element={<Home />} />
              </Route>

              {/* Booking info routes */}
              <Route
                path="/booking-info/:tenantId/:subtenantId?"
                element={<BookingInfo />}
              >
                <Route index element={<CalenderInfo />} />
                <Route path="availability" element={<AvailabilityInfo />} />
                <Route path="information" element={<InformationInfo />} />
                <Route path="confirm" element={<ConfirmInfo />} />
              </Route>

              {/* Reservations routes */}
              <Route
                path="/reservations/:tenantId/:subtenantId?/:bookingID"
                element={<Reservations />}
              >
                <Route index element={<GuestInformation />} />
                <Route path="confirmed" element={<GuestConfirm />} />
              </Route>

              {/* Bill info */}
              <Route path="/bill-info/:reservationID" element={<BillsInfo />}>
                <Route index element={<PayingBillInfo />} />
                <Route path="split" element={<SplitBillInfo />} />
                <Route path="card" element={<PaymentFormInfo />} />
                <Route path="succeeded" element={<SucceededPaymentInfo />} />
              </Route>

              {/* Static pages */}
              <Route
                path="/frequently-asked-questions"
                element={<FrequentlyAskedQuestions headerSpace />}
              />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions headerSpace />}
              />
            </Routes>
          </Suspense>
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={false}
            closeOnClick
            draggable
            progress={undefined}
            theme="dark"
          />
        </QueryClientProvider>
      </div>
    </Router>
  )
}

export { App }
